const initialState = {};
export default function socketReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "socket/init":
      return payload;

    default:
      return state;
  }
}
