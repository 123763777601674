import axios from "axios";
import authHeader from "./auth-header";
let baseURL = process.env.REACT_APP_DEV_PROXY;
if (process.env.REACT_APP_ENV === "production") {
  baseURL = process.env.REACT_APP_PROD_PROXY;
} else if (process.env.REACT_APP_ENV === "staging") {
  baseURL = process.env.REACT_APP_STAG_PROXY;
}
const API_URL = `${baseURL}/api/eventos`;
const getEventos = () => {
  return axios.get(`${API_URL}`, { headers: authHeader() });
};
const getEvento = (id) => {
  return axios.get(API_URL + "/evento/" + id, {
    headers: authHeader(),
  });
};

const createEvento = (evento) => {
  return axios.post(API_URL + "/evento", evento, {
    headers: authHeader(),
  });
};
const updateEvento = (id, evento) => {
  return axios.put(API_URL + "/evento/" + id, evento, {
    headers: authHeader(),
  });
};
const deleteEvento = (id) => {
  return axios.delete(API_URL + "/evento/" + id, {
    headers: authHeader(),
  });
};
const funcs = {
  getEventos,
  getEvento,
  createEvento,
  updateEvento,
  deleteEvento,
};
export default funcs;
