import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { fetchUser } from "./reducers/user.reducer";
import { io } from "socket.io-client";
import "./scss/style.scss";
import AuthService from "./services/auth.service";
import eventoService from "./services/evento.service";
import jwtDecode from "jwt-decode";
import { format } from "date-fns";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
let baseURL = process.env.REACT_APP_DEV_WS;
if (process.env.REACT_APP_ENV === "production") {
  baseURL = process.env.REACT_APP_PROD_WS;
} else if (process.env.REACT_APP_ENV === "staging") {
  baseURL = process.env.REACT_APP_STAG_WS;
}

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Pwdreset = React.lazy(() => import("./views/pages/pwdreset/Pwdreset"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Preview = React.lazy(() => import("./views/tv/Preview/Preview"));
const KpiPaneles = React.lazy(() => import("./views/kpi/preview/paneles"));
const Calendar = React.lazy(() =>
  import("./views/mkt/calendario/components/Calendar/Calendar")
);
const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [eventos, setEventos] = useState([]);
  const readEventos = async () => {
    const response = await eventoService.getEventos();
    setEventos(response.data);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    dispatch({
      type: "socket/init",
      payload: io(
        `ws${
          process.env.REACT_APP_ENV === "development" ? "" : "s"
        }://${baseURL}`
      ),
    });
    if (user) {
      setCurrentUser(user);
      let decodedToken = jwtDecode(user.accessToken);

      dispatch(fetchUser(user.id));
      const exp = new Date(decodedToken.exp * 1000);
      const now = new Date();
      const expireTime = exp - now;
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(false);
      }, expireTime);
    }
    readEventos();
    // eslint-disable-next-line
  }, []);
  const logOut = () => {
    AuthService.logout();
    setCurrentUser(null);
    window.location.reload(false);
  };
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} user={currentUser} />}
          />
          <Route
            exact
            path="/pwdreset"
            name="Recuperar Contraseña"
            render={(props) => <Pwdreset {...props} />}
          />
          <Route
            exact
            path="/tv/L/preview"
            name="Preview"
            component={Preview}
          />
          <Route
            exact
            path="/kpi/preview/paneles"
            name="KPI Panales"
            component={KpiPaneles}
          />
          <Route
            exact
            path="/mkt/calendario/preview"
            name="Calendar"
            render={(props) => (
              <Calendar
                {...props}
                fecha={format(new Date(), "yyyy-MM-dd")}
                width={1920}
                height={1080}
                eventos={eventos}
              />
            )}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => (
              <TheLayout {...props} user={currentUser} logOut={logOut} />
            )}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
