import { createStore, combineReducers, applyMiddleware } from "redux";
import okrReducer from "./reducers/okr.reducer";
import statusReducer from "./reducers/status.reducer";
import userReducer from "./reducers/user.reducer";
import funcionarioReducer from "./reducers/funcionarioReducer";
import paginasReducer from "./reducers/paginasReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import socketReducer from "./reducers/socket.reducer";
const initialState = "responsive";
const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));
const changeState = (state = initialState, action) => {
  switch (action.type) {
    case "set":
      return action.sidebarShow;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  sidebarShow: changeState,
  OKRs: okrReducer,
  status: statusReducer,
  funcionario: funcionarioReducer,
  user: userReducer,
  socket: socketReducer,
  paginas: paginasReducer,
});

const store = createStore(rootReducer, composedEnhancer);
window.store = store;

export default store;
