/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";
let baseURL = process.env.REACT_APP_DEV_PROXY;
if (process.env.REACT_APP_ENV === "production") {
  baseURL = process.env.REACT_APP_PROD_PROXY;
} else if (process.env.REACT_APP_ENV === "staging") {
  baseURL = process.env.REACT_APP_STAG_PROXY;
}

const API_URL = `${baseURL}/api/user/`;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const getAllUsers = () => {
  return axios.get(API_URL + "read");
};

const getUser = (id) => {
  return axios.get(API_URL + "read/" + id);
};
const updateUser = (id, user) => {
  return axios.put(API_URL + "update/" + id, user, {
    headers: authHeader(),
  });
};
export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getAllUsers,
  getUser,
  updateUser,
};
