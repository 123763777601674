import axios from "axios";
import { getQuarter } from "date-fns";
const initialOKR = { OKRs: [], historico: [], createdAt: "" };

let baseURL = process.env.REACT_APP_DEV_PROXY;
if (process.env.REACT_APP_ENV === "production") {
  baseURL = process.env.REACT_APP_PROD_PROXY;
}
const API_URL = `${baseURL}/api/okr/`;

export default function okrReducer(
  state = initialOKR,
  {
    type,
    nombreOKR,
    nombreKR,
    nombreComment,
    atIndexOKR,
    atIndexKR,
    atIndexComment,
    e,
    author,
    payload,
    authorId,
  }
) {
  switch (type) {
    case "okr/add":
      return {
        ...state,
        OKRs: [
          ...state.OKRs.map((OKR, indexOKR) => {
            return indexOKR === state.OKRs.length - 1
              ? {
                  nombre: nombreOKR,
                  KRs: [],
                  creado: new Date(),
                  trimestre: getQuarter(new Date()),
                  anho: new Date().getFullYear(),
                }
              : OKR;
          }),

          {},
        ],
      };
    case "okr/edit":
      return {
        ...state,
        OKRs: state.OKRs.map((OKR, indexOKR) => {
          return indexOKR === atIndexOKR
            ? { ...OKR, [e.target.name]: e.target.value }
            : OKR;
        }),
      };
    case "okr/remove":
      return {
        ...state,
        OKRs: state.OKRs.filter((okr, indexOKR) => indexOKR !== atIndexOKR),
      };
    case "okr/addkr":
      return {
        ...state,
        OKRs: state.OKRs.map((OKR, index) =>
          index === atIndexOKR
            ? {
                ...OKR,
                KRs: [
                  ...OKR.KRs,
                  { nombre: nombreKR, progress: 0, comments: [] },
                ],
              }
            : OKR
        ),
      };
    case "okr/editkr":
      return {
        ...state,
        OKRs: state.OKRs.map((okr, index) =>
          index === atIndexOKR
            ? {
                ...okr,
                KRs: okr.KRs.map((kr, index2) =>
                  index2 === atIndexKR
                    ? { ...kr, [e.target.name]: e.target.value }
                    : kr
                ),
              }
            : okr
        ),
      };
    case "okr/removekr":
      return {
        ...state,
        OKRs: state.OKRs.map((OKR, indexOKR) =>
          indexOKR === atIndexOKR
            ? {
                ...OKR,
                KRs: OKR.KRs.filter((kr, indexKR) => indexKR !== atIndexKR),
              }
            : OKR
        ),
      };
    case "okr/addcomment":
      return {
        ...state,
        OKRs: state.OKRs.map((okr, index) =>
          index === atIndexOKR
            ? {
                ...okr,
                KRs: okr.KRs.map((kr, index2) =>
                  index2 === atIndexKR
                    ? {
                        ...kr,
                        comments: [
                          ...kr.comments,
                          {
                            nombre: nombreComment,
                            author: author,
                            authorId: authorId,
                            at: new Date(),
                          },
                        ],
                      }
                    : kr
                ),
              }
            : okr
        ),
      };
    case "okr/editcomment":
      return {
        ...state,
        OKRs: state.OKRs.map((okr, index) =>
          index === atIndexOKR
            ? {
                ...okr,
                KRs: okr.KRs.map((kr, index2) =>
                  index2 === atIndexKR
                    ? {
                        ...kr,
                        comments: kr.comments.map((comment, index3) =>
                          index3 === atIndexComment
                            ? { ...comment, [e.target.name]: e.target.value }
                            : comment
                        ),
                      }
                    : kr
                ),
              }
            : okr
        ),
      };
    case "okr/removecomment":
      return {
        ...state,
        OKRs: state.OKRs.map((OKR, indexOKR) =>
          indexOKR === atIndexOKR
            ? {
                ...OKR,
                KRs: OKR.KRs.map((kr, indexKR) =>
                  indexKR === atIndexKR
                    ? {
                        ...kr,
                        comments: kr.comments.filter(
                          (comment, indexComment) =>
                            indexComment !== atIndexComment
                        ),
                      }
                    : kr
                ),
              }
            : OKR
        ),
      };
    case "okr/loadOKRs":
      return payload;
    case "okr/updateOKRs":
      return { ...state, historico: payload.historico };
    default:
      return state;
  }
}
export function fetchOKR(id_funcionario) {
  return async function fetchOKRThunk(dispatch) {
    let response = await axios.get(`${API_URL}read/${id_funcionario}`);
    if (response.data.length === 0) {
      response = await axios.post(`${API_URL}create`, {
        id_funcionario: id_funcionario,
        OKRs: [{}],
        historico: [],
        lastModifier: id_funcionario,
      });
    }

    dispatch({ type: "okr/loadOKRs", payload: response.data[0] });
  };
}
export function updateOKR(id_funcionario) {
  return async function saveNewOKRThunk(dispatch, getState) {
    dispatch({ type: "status/loading", payload: "guardando..." });
    const {
      OKRs: { OKRs },
    } = getState();
    let config = {
      headers: {
        "x-access-token": JSON.parse(window.localStorage.user).accessToken,
      },
    };
    try {
      const response = await axios.put(
        `${API_URL}update`,
        {
          OKRs,
          id_funcionario,
        },
        config
      );
      dispatch({ type: "okr/updateOKRs", payload: response.data });
      dispatch({ type: "status/succeeded", payload: "Guardado con exito" });
    } catch (err) {
      dispatch({ type: "status/error", payload: err.message });
    }
  };
}
