const initialState = [];
export default function paginasReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case "paginas/read":
      return payload;
    case "paginas/updateOnePage":
      const { cambio } = payload;
      const index = state.findIndex((pagina) => {
        return pagina?._id.toString() === cambio?._id.toString();
      });

      return state
        .map((pagina, indexP) =>
          indexP === index
            ? {
                ...pagina,
                ...cambio,
                clientes: cambio.clientes.filter((cliente) => cliente.active),
                id: cambio._id,
              }
            : pagina
        )
        .sort((a, b) => a.pagina - b.pagina);

    default:
      return state;
  }
}
